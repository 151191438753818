<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.monthName,
            getHelperObject('late_payment_type'),
            helpers.rounding,
            { key: 'month_day', _classes: 'text-center' },
            helpers.decimalValue,
            { ...helpers.validIcons, key: 'status_id' }
          ]"
          :config="{
            url: 'admin/property_late_payments',
            route: '/admin/parameters/property_late_payments',
            name: 'property_late_payment',

            params: { _lists: 'months,roundings,late_payment_types' },
            duplicate: true,
            latest: 'month_id'
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PropertyLatePayments',
  mixins: [crudMixin]
}
</script>
