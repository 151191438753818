var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          _vm.getHelperObject('late_payment_type'),
          _vm.helpers.rounding,
          { key: 'month_day', _classes: 'text-center' },
          _vm.helpers.decimalValue,
          Object.assign({}, _vm.helpers.validIcons, {key: 'status_id'})
        ],"config":{
          url: 'admin/property_late_payments',
          route: '/admin/parameters/property_late_payments',
          name: 'property_late_payment',

          params: { _lists: 'months,roundings,late_payment_types' },
          duplicate: true,
          latest: 'month_id'
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }